window.angular
    .module('MyHippoProducer.Components')
    .directive('incentiveSidebar', function () {
        return {
            restrict: 'E',
            scope: {},
            templateUrl: 'components/incentive-sidebar/incentive-sidebar.html',
            controller: function ($scope, HeapService, IncentivesService) {
                $scope.incentivesTabIsOpen = !sessionStorage.getItem('closeInsentiveSidebar');
                $scope.showIncentive = false;
                $scope.incentiveFinished = false;
                $scope.incentiveCompleteAt = '';
                $scope.incentiveAmount = '';
                $scope.incentiveSidebarContent = '';
                $scope.incentiveSidebarTitle = '';
                $scope.incentiveLink = '';
                $scope.incentiveEndAt = '';
                $scope.incentiveLeftDays = 0;
                $scope.incentiveDuration = 0;

                const incentiveQuery = '{ Incentive { progressTrackerTitle progressTrackerText startDate endDate achievedDate status rewardAmount confirmationBannerLink } }';

                IncentivesService.fetchIncentives(incentiveQuery).then((response) => {
                    const incentiveData = response.data.data.Incentive.find((incentive) => incentive.status === 'ACTIVE' || incentive.status === 'COMPLETED' );
                    const { status, startDate, endDate, achievedDate, rewardAmount, progressTrackerTitle, progressTrackerText, confirmationBannerLink } = incentiveData;
                    const endTime = moment(endDate).utc().endOf('days').format('MM/DD/YYYY HH:mm:ss');
                    $scope.incentiveLeftDays = moment(endTime, ['MM/DD/YYYY HH:mm:ss']).diff(new Date(), 'days');
                    $scope.incentiveDuration = moment(endTime, ['MM/DD/YYYY HH:mm:ss']).diff(startDate, 'days');
                    $scope.incentiveFinished = status === 'COMPLETED';
                    $scope.incentiveCompleteAt = moment(achievedDate).utc().format('MM/DD/YYYY');
                    $scope.incentiveAmount = rewardAmount;
                    $scope.incentiveSidebarContent = progressTrackerText;
                    $scope.incentiveSidebarTitle = progressTrackerTitle;
                    $scope.incentiveLink = confirmationBannerLink;
                    $scope.incentiveEndAt = endTime;
                    $scope.showIncentive = status === 'ACTIVE' || (status === 'COMPLETED' && $scope.incentiveLeftDays >= -30);

                    if ($scope.showIncentive) {
                        HeapService.trackEvent('incentive_tab_loaded');
                    }

                }).catch(() => {
                    $scope.showIncentive = false;
                });

                $scope.triggerIncentivesTab = function () {
                    HeapService.trackEvent('incentive_tab_click_earn_rewards');
                    $scope.incentivesTabIsOpen = !$scope.incentivesTabIsOpen;
                };
                $scope.closeIncentivesTab = function () {
                    HeapService.trackEvent('incentive_tab_click_close_tab');
                    $scope.incentivesTabIsOpen = false;
                    sessionStorage.setItem('closeInsentiveSidebar', '1');
                };
            }
        };
    });

angular.module('MyHippoProducer.Components').animation('.incentives-tab-container', ['$animateCss', function ($animateCss) {
    return {
        enter: function (element) {
            return $animateCss(element, {
                event: 'enter',
                structural: true,
                addClass: 'tab-enter',
                removeClass: 'tab-leave',
            });
        },
        leave: function (element) {
            return $animateCss(element, {
                event: 'leave',
                structural: true,
                addClass: 'tab-leave',
                removeClass: 'tab-enter',
            });
        }
    };
}]);

angular.module('MyHippoProducer.Components').animation('.vertical-tab', ['$animateCss', function ($animateCss) {
    return {
        enter: function (element) {
            return $animateCss(element, {
                event: 'enter',
                structural: true,
                addClass: 'vertical-tab-enter',
                removeClass: 'vertical-tab-leave',
            });

        },
        leave: function (element) {
            return $animateCss(element, {
                event: 'leave',
                structural: true,
                addClass: 'vertical-tab-leave',
                removeClass: 'vertical-tab-enter',
            });
        }
    };
}]);
